.userCarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
}

.carouselWrapper {
  max-width: 38rem;
  overflow: hidden;
  position: relative;
}

/* Add these mask gradients to create fade effect */
.carouselWrapper::before,
.carouselWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1rem;
  z-index: 2;
  pointer-events: none;
}

/* Left side gradient fade */
.carouselWrapper::before {
  left: 0;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

/* Right side gradient fade */
.carouselWrapper::after {
  right: 0;
  background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

/* Container for the scrolling cards */
.carouselContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  will-change: transform;
}

.userCard {
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 11.16rem;
  height: 14.625rem;
  cursor: pointer;
  transition: 0.2s;
  flex: 0 0 auto;
}

.userCard:hover {
  background-color: #f0f0f0;
}

.userImage {
  width: 4rem;
  height: 4rem;
  overflow: hidden;
}

.userImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 50%;
}

.userInfo {
  display: flex;
  flex-direction: column;
  /* flex-grow: 1; */
  gap: 0.5;
}

.userName {
  color: black;
  font-weight: 600;
  font-size: 1.5rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0.5rem;
  text-align: left;
}

.userDescription {
  font-size: 1rem;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
  transition: 0.2s;
  margin: 0;
  text-align: left;
}

.userCard:hover .userDescription {
  color: black;
}

.userCard:hover .viewLink {
  color: black;
}

.viewLink {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: gray;
  font-size: 1rem;
  transition: 0.2s;
}

.viewLink .material-symbols-outlined {
  font-size: 1.25rem;
  transform: rotate(45deg);
}

@media (max-width: 768px) {
  .carouselWrapper {
    max-width: 100%;
    width: 100%;
  }
}
