.googleIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.page {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  position: relative;
}

.closeButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
}
.closeButton:hover {
  background-color: #f8f8f8;
}

.content {
  /* background-color: #f8f8f8; */
  width: 100%;
  width: 24rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.secondaryAbout {
  color: gray;
  font-size: 1rem;
  margin-top: 1rem;
}

.secondaryAbout span {
  color: orangered;
  font-weight: 600;
}

.checkingText {
  display: flex;
  align-items: center;
  font-weight: normal !important;
  color: gray !important;
  font-size: 0.875rem;
  margin-top: 1rem;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  width: 24rem;
  /* background-color: rebeccapurple; */
}

.formContent {
  width: 100%;
  padding: 0.5rem;
}

.formAbout {
  /* margin-bottom: 1.5rem; */
  text-align: center;
}

.primaryAbout {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.divider {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 1rem;
  padding-bottom: 1rem;
  margin-bottom: 1rem;

  margin-left: 1rem;
  margin-right: 1rem;

  color: gray;
  font-size: 0.875rem;
}

.termsText {
  text-align: center;
  color: gray;
  font-size: 0.875rem;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

.interactions,
.altSection {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.altSection {
  margin-bottom: 1rem;
}

.label {
  margin-bottom: 0.5rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */

  color: gray;
  font-size: 0.875rem;
  font-weight: 600;
}

.email,
.button,
.altEmail {
  padding: 0.75rem 1rem;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
}

.email {
  background-color: #f8f8f8;
  color: black;
  border: 0.1rem solid #e0e0e0;
  margin-bottom: 1rem;
}

.email::placeholder {
  color: gray;
}

.altEmail {
  border-radius: 0.5rem;
  background-color: white;
  color: gray;
  border: 0.1rem solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-weight: 600;
  transition: 0.2s;
}

.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 600;
  width: 20rem;
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.repostCloseButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.tickIcon {
  margin-right: 0.5rem;
}

.closeIcon {
  transition: 0.2s;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  z-index: 10;
}

.closeIcon:hover {
  background-color: #f8f8f8;
}

.altEmail:hover {
  background-color: #f8f8f8;
}

.button {
  border-radius: 0.5rem;
  background-color: orangered;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  margin-top: 1rem;
  transition: 0.2s;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(90%);
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.checkIcon {
  margin-right: 0.25rem;
  color: gray !important;
  font-size: 0.875rem;
  animation: spin 1s linear infinite;
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.link {
  color: orangered;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}
.link:hover,
.link2:hover {
  text-decoration: underline;
}

.link2 {
  color: gray;
  text-decoration: underline;
  cursor: pointer;
}

.forgotPasswordLink {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
  /* margin-left: 1rem;
  margin-right: 1rem; */
  font-size: 0.875rem;
}

.link3 {
  color: orangered;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

@media (max-width: 640px) {
  .formContent {
    padding: 1rem;
  }
}
