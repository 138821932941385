/* SuggestionCarousel.module.css */
.suggestionCarousel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  margin-top: 1rem;
}

.carouselWrapper {
  max-width: 38rem;
  overflow: hidden;
  position: relative;
}

/* Add these mask gradients to create fade effect */
.carouselWrapper::before,
.carouselWrapper::after {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  width: 1rem;
  z-index: 2;
  pointer-events: none;
}

/* Left side gradient fade */
.carouselWrapper::before {
  left: 0;
  background: linear-gradient(to right, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

/* Right side gradient fade */
.carouselWrapper::after {
  right: 0;
  background: linear-gradient(to left, #fff 0%, rgba(255, 255, 255, 0) 100%);
}

.carouselContainer {
  display: flex;
  flex-direction: row;
  gap: 1rem;
  will-change: transform;
}

.suggestionButton {
  flex: 0 0 auto;
  background-color: #f8f8f8;
  border: none;
  border-radius: 0.5rem 0.5rem 0 0.5rem !important;
  padding: 1rem;
  white-space: nowrap;
  font-size: 1rem;
  color: gray;
  cursor: pointer;

  transition: 0.2s;
}

.suggestionButton:hover {
  color: black;
  background-color: #f0f0f0;
}

@media (max-width: 768px) {
  .suggestionCarousel {
    width: 100%;
  }
}
