.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
  overflow-y: auto;
}

.modalContent {
  position: fixed;
  top: 50%;
  /* left: 50%; */
  transform: translateX(-50%);
  transform: translateY(-50%);
  border-radius: 1rem;
  width: 32rem;
  height: 32rem;
  background-color: white;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.closeIcon {
  /* background-color: whitesmoke; */

  cursor: pointer;
  width: fit-content;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  align-self: flex-end;
  color: gray;
  padding: 0.5rem;
  transition: 0.3s;
  border-radius: 0.5rem;
}

.closeIcon:hover {
  background-color: whitesmoke;
}

.cardHeader {
  background-color: white;
  padding: 1rem;

  /* border-radius: 1rem 1rem 0 0; */
}

.cardTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
}

.interactions {
  margin-left: 1rem;
  margin-right: 1rem;

  flex: 1;
  overflow: hidden;
  position: relative;
}

.interactions::-webkit-scrollbar {
  display: none;
}

.textArea {
  border-radius: 0.5rem;
  text-transform: lowercase;
  width: 100%;
  height: 100%;
  padding: 1rem;
  color: black;
  border: none;
  resize: none;
  overflow-y: auto;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  outline: none;
  background-color: whitesmoke;
}

.textArea:focus {
  outline: none;
  border: none;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.cardFooter {
  background-color: white;
  padding: 1rem;
  padding-bottom: 1rem;
}

.button {
  padding: 0.5rem;
  border-radius: 0rem;
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  display: flex;
  cursor: pointer;
  width: 100%;
  font-size: 1rem;
  transition: 0.3s;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIndicator {
  display: flex;
  margin-top: 1rem;
  justify-content: center;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem;
  color: gray;
}

.saveIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem;
  color: white;
}

.loadingIcon,
.saveIcon {
  padding: 0.1rem;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  margin-bottom: 0.5rem;
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
}

/* Mobile styles */
@media (max-width: 991px) {
  .modalContent {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    transform: none;
    width: 100%;
    height: 100%;
    border-radius: 0;
  }

  .cardHeader {
    border-radius: 0;
  }

  .textArea {
    height: 100%;
  }

  .cardFooter {
    padding-bottom: 2rem;
  }

  .button {
    width: 100%;
  }
}
