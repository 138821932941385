.sidebar {
  width: 16rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f8f8f8;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 20;
  transition: 0.2s;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.topBar {
  position: sticky;
  top: 0;
  width: 100%;
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background-color: #f8f8f8;
}

/* Logo button */
.logoButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: 0.2s;
}

.logoButton:hover {
  background-color: #f0f0f0;
}

.topBarRightButtons {
  display: flex;
  align-items: center;
}

.iconButton {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  position: relative;
}

.iconButton:hover {
  background-color: #f0f0f0;
}

.logo {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
}

/* Add spacer to push content to bottom */
.spacer {
  flex-grow: 1;
}

/* Content wrapper for bottom content */
.contentWrapper {
  padding: 0 1rem;
}

.title {
  color: #000;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
}

.subtitle {
  color: black;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer {
  border-top: 0.05rem solid lightgray;
  margin-top: 1rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.copyright {
  font-size: 0.875rem;
  margin: 0;
  color: gray;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.link {
  font-size: 0.875rem;
  color: gray;
  text-decoration: none;
}
.link:hover {
  color: gray;
  text-decoration: underline;
}

.bold {
  font-weight: 900;
  color: black;
}

.buttons {
  display: flex;
  flex-direction: column;
}

.tryButton {
  margin-top: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: orangered;
  color: white;
  padding: 0.5rem;
  border: none;
  border-radius: 0.5rem;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tryButton:hover {
  filter: brightness(90%);
}

.tryButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
  color: black;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
}

.input::placeholder {
  color: gray;
}

.input:focus {
  outline: none;
}

.loading {
  position: relative;
  cursor: not-allowed;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.messagePopup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

@media (max-width: 60rem) {
  .sidebar.open {
    width: 100%;
  }

  .footer {
    padding-bottom: 2rem;
  }
}
