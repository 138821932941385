.container {
  min-height: 100vh;
  box-sizing: border-box;
  overflow: auto;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-top: 0.5rem;
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  margin-bottom: 0 !important;
  padding-bottom: 0 !important;
  border-radius: 1rem 1rem 0 0;
  align-items: center;
  justify-content: flex-start;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
  transition: 0.2s;
  z-index: 25;
}

.container.withSidebar {
  margin-left: 16rem;
  position: relative;
}

.container.withProfileSidebar {
  margin-right: 16rem;
}

.container.withSidebar.withProfileSidebar {
  margin-left: 16rem;
  margin-right: 16rem;
}

/* Remove these selectors as they're now redundant with direct container classes */
/* :where(.withSidebar) .container {
  margin-left: 16.5rem;
}

:where(.withProfileSidebar) .container {
  margin-right: 16.5rem;
}

:where(.withSidebar.withProfileSidebar) .container {
  margin-left: 16.5rem;
  margin-right: 16.5rem;
} */

.topBlock {
  display: none;
}

.profileButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 1rem;
  right: 1rem;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
  z-index: 5 !important;
}

.image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;

  z-index: 5 !important; /* Make sure it's higher than any other elements */
}

.mainContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 6.95rem;
  transition: 0.2s;
}

/* Remove margin adjustments from mainContent */
.mainContent.withSidebar,
.mainContent.withProfileSidebar,
.mainContent.withProfileCard {
  margin: 0 !important;
}

/* Make withProfileCard behave exactly like withProfileSidebar,
   but only for container, not for content inside */
.withProfileCard {
  margin-right: 16rem;
}

/* Keep original input form positioning */
.withProfileCard .inputForm {
  left: calc(50% - 8rem);
}

.withSidebar .inputForm {
  left: calc(50% + 8rem);
}

.withProfileSidebar .inputForm {
  left: calc(50% - 8rem);
}

.chatContainer {
  width: 40rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  align-self: center;
}

/* Remove these margin classes or override them for mainContent */
.withSidebar {
  margin-left: 16rem;
}

.withProfileSidebar {
  margin-right: 16rem;
}

/* When both sidebars are open */
.withSidebar.withProfileSidebar .chatContainer {
  width: calc(100% - 2rem) !important;
  max-width: 40rem !important;
  margin: 0 auto !important;
}

.withSidebar .chatContainer {
  margin: 0 auto !important;
}

.messagesContainer {
  width: 40rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto !important;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 4rem;
  padding-bottom: 4rem;

  align-self: center;
}

.userMessage .avatarImage {
  cursor: pointer;
  border-radius: 50%; /* Apply 50% border radius only to user images */
}

/* Updated message styles for avatar display */
.message {
  display: flex;
  /* padding: 1rem; */
  color: black;
  word-wrap: break-word;
  align-items: flex-start;
}

.singleLineMessage {
  align-items: center !important; /* Override the default alignment */
}

/* Avatar styling */
.messageAvatar {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.avatarImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  /* Apply border-radius by default */
  border-radius: 50%;
}

/* Override for AI avatars specifically */
.assistantMessage .avatarImage {
  border-radius: 0.5rem; /* Remove border-radius for AI */
}

/* Exception for direct messages - they should have rounded avatars */
.directChatUserAvatar .avatarImage {
  cursor: pointer;
  border-radius: 50%; /* Keep rounded for direct chat */
}

/* For direct messages between users, ensure rounded avatar images */
.directChatUserAvatar {
  border-radius: 50%;
}

/* In direct messages, ensure both sides have rounded avatars */
.directMessage .avatarImage {
  border-radius: 50%;
}

.directMessage {
  white-space: pre-wrap; /* Add this to direct messages specifically */
  word-wrap: break-word; /* Add this to ensure long words break */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  width: 100%; /* Ensure full width */
}

/* Message content container */
.messageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  white-space: pre-wrap; /* Ensure this is present */
  word-wrap: break-word; /* Add this to ensure long words break */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  max-width: calc(
    100% - 2.5rem
  ); /* Ensure content doesn't push beyond container width */
}

.userMessage {
  border-radius: 0.5rem 0.5rem 0 0.5rem;
  background-color: #f8f8f8;
  padding: 1rem;
  color: black;
  margin: 0;
  align-self: flex-end;
}

.welcomeTitle {
  font-size: 2rem;
  font-weight: 600;
  color: black;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 2rem;
}

/* Reduced margin for direct chat title */
.directChatTitle {
  margin-bottom: 1rem !important;
}

/* In Chat.module.css */
.welcomeMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 69vh;
  text-align: center;
}

.centeredInputForm {
  position: fixed !important; /* Keep it fixed */
  bottom: 0 !important; /* Keep it at bottom */
  border-radius: 1rem 1rem 0 0 !important;
  /* We can still customize the appearance without changing positioning */
  background-color: white;
  border: 0.05rem solid lightgray;
}
.assistantMessage {
  /* background-color: whitesmoke; */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */

  border-radius: 0.5rem;

  color: black;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: nowrap;
  /* gap: 1rem; */
}

a {
  word-break: break-all; /* This allows URLs to break at any character */
}

.messageText {
  width: 100%;
  /* padding: 1rem; */
  padding-top: 0;
  padding-bottom: 0;
  white-space: pre-wrap; /* Explicitly ensure wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.profileCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.profileCard {
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 11.16rem;
  height: 14.625rem;
  cursor: pointer;
  transition: 0.2s;
}

.profileCard:hover {
  background-color: #f0f0f0;
}

.profileImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.profileName {
  color: black;
  font-weight: 600;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileBio {
  transition: 0.2s;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
}

.profileCard:hover .profileBio {
  color: black;
}

.profileCard:hover .viewLink {
  color: black;
}

.viewLink {
  margin-top: auto;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  color: gray;
  font-size: 1rem;
  transition: 0.2s;
}

.viewLink .material-symbols-outlined {
  font-size: 1.25rem;
  transform: rotate(45deg);
}

.welcomeMessage ul {
  list-style: none;
  padding: 0;
  /* margin: 1rem 0; */
}

.welcomeMessage li {
  /* margin: 0.5rem 0; */
  color: orangered;
}

.sidebarOpenButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 30;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.sidebarOpenButton:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.sidebarOpenButton2 {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 30;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disclaimer {
  position: fixed;
  bottom: 0.5rem !important;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: 0.2s;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  color: gray;
}

.inputForm {
  border-radius: 1rem 1rem 0 1rem !important;
  position: fixed;
  bottom: 1rem !important;
  left: 50%;
  transform: translateX(-50%);
  width: 40rem;
  max-width: calc(100% - 2rem);
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: 0.2s;
  border: none;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
}

.withSidebar.withProfileSidebar .inputForm {
  left: 50%;
}

.chat {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: none;
  border: none;
  outline: none;
  color: black;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  resize: none;
  max-height: 25vh;
  padding: 1rem 1rem 0 1rem;
  /* text-transform: lowercase; */
}

.chat::placeholder {
  color: gray;
}

.controlBar {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.img {
  width: 1rem;
  height: 1rem;
  border-radius: 0.25rem;
  margin-right: 0.5rem;
  object-fit: cover; /* Add this to make sure images are properly cropped */
  /* Remove the border-radius here as we're applying it conditionally in JSX */
}

/* Add this class for user images specifically if you want a consistent style */
.userTagImg {
  border-radius: 50%;
}

/* You might also want to update the tag for better alignment with larger user names */
.tag {
  background-color: #f0f0f0;
  padding: 0.625rem;
  color: gray;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  overflow: hidden; /* Hide overflow content */
  text-overflow: ellipsis; /* Add ellipsis for text overflow */
  white-space: nowrap; /* Keep everything on a single line */
}

.sendButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: orangered;
  border: none;
  color: white;
  font-weight: 900;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  transition: 0.2s;
}
.sendButton:disabled {
  background-color: #f0f0f0;
  color: gray;
  cursor: not-allowed;
}
.sendButton:hover {
  opacity: 0.5;
}

.sendButton:disabled:hover {
  opacity: 1;
}

.loadingMessage {
  align-self: flex-start;

  /* padding: 1rem; */
}

.typingIndicator {
  display: flex;
  color: orangered;
  font-weight: 900;
  gap: 0.25rem;
}
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  color: orangered;
  font-size: 1rem;
  margin-left: 0.5rem;
  animation: spin 1.5s linear infinite;
}

/* Sidebar Styles */
.sidebar {
  width: 16rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  z-index: 15;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.topBar {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 30;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggleButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleButton:hover {
  background-color: #202020;
}

.logo {
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
}

.logo:hover {
  background-color: #202020;
}

.title {
  color: #000;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.subtitle {
  color: gray;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer {
  border-top: 0.05rem solid gray;
  margin-top: 1rem;
  margin-left: 1rem;
  margin-right: 0.5rem !important;

  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.copyright {
  font-size: 0.875rem;
  color: gray;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.link {
  font-size: 0.875rem;
  color: gray;
  text-decoration: none;
}

.link:hover {
  color: white;
  text-decoration: underline;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tryButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: orangered;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.tryButton:hover {
  filter: brightness(90%);
}

.loginButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: #f8f8f8;
  color: gray;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.loginButton:hover {
  filter: brightness(90%);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  background-color: #202020;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
}

.input::placeholder {
  color: gray;
}

.input:focus {
  outline: none;
}

/* Direct chat header styling */
.directChatHeader {
  padding: 1rem;
  border-bottom: 1px solid #f8f8f8;
  margin-bottom: 1rem;
}

.directChatUser {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.directChatUserImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
}

.directChatUserInfo {
  display: flex;
  flex-direction: column;
}

.directChatUserName {
  font-weight: bold;
}

html,
body {
  overflow-x: hidden;
  width: 100%;
  position: relative;
}

@media (max-width: 768px) {
  .messagesContainer {
    width: 100%;
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .message {
    max-width: 100%;
    box-sizing: border-box;
  }

  .messageContent {
    max-width: calc(100% - 2.5rem);
    overflow-wrap: anywhere; /* Stronger than break-word, will break at any point if needed */
    hyphens: none; /* Add hyphens for better word breaks */
  }

  /* Target long links specifically */
  .messageContent a,
  .messageText a {
    word-break: break-all;
    display: inline-block;
    max-width: 100%;
  }

  /* Force text to wrap aggressively in user messages (where links often appear) */
  .userMessage {
    overflow-wrap: anywhere;
    word-break: break-word;
  }

  /* Force URLs and long strings to wrap */
  .messageText {
    overflow-wrap: anywhere; /* Most aggressive wrapping */
    word-break: break-word;
    max-width: 100%;
  }

  .welcomeMessage {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: hidden !important;
  }

  /* Fix for the UserCarousel component at mobile sizes */
  .userCarousel {
    width: 100% !important;
    max-width: 100% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 1rem !important;
    padding-right: 1rem !important;
    box-sizing: border-box !important;
    overflow-x: clip !important;
  }

  /* Fix for carousel wrapper */
  .carouselWrapper {
    width: 100% !important;
    max-width: 100% !important;
    overflow-x: clip !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Ensure container constrains all content */
  .container {
    overflow-x: hidden !important;
    width: calc(100% - 1rem) !important;
  }

  /* Ensure main content doesn't allow horizontal scroll */
  .mainContent {
    overflow-x: hidden !important;
    width: 100% !important;
  }
}

/* Add a general rule for non-breaking content like URLs */
.messageContent,
.messageText,
.directMessage {
  overflow-wrap: break-word;
  word-break: break-word; /* Apply to all breakpoints */
}

/* Fix for long URLs with no spaces */
a,
[href],
.messageContent:has(a) {
  word-break: break-all;
  max-width: 100%;
}

/* Direct fixes for the mobile input form */

/* Target the input form specifically at mobile widths */
@media (max-width: 42rem) {
  .container {
    min-height: 99vh;
    box-sizing: border-box;
    overflow: auto;
    background-color: white;
    position: relative;
    display: flex;
    flex-direction: column;
    margin: 0rem !important;

    margin-bottom: 0 !important;
    padding-bottom: 0 !important;
    border-radius: 1rem 1rem 0 0;
    align-items: center;
    justify-content: flex-start;
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
    transition: 0.2s;
    z-index: 25;
  }

  /* Force full width with no margins or gaps */
  .inputForm {
    left: 1rem !important;
    right: 1rem !important;
    box-sizing: border-box !important;
    transform: none !important;
    border: none !important;

    position: fixed !important;
    bottom: 1rem !important;
  }

  /* Ensure text input inside form is also full width */
  .chat {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
  }

  /* Fix for welcome message with carousel */
  .welcomeMessage {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Fix for the UserCarousel component at mobile sizes */
  .userCarousel {
    width: 100vw !important;
    max-width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Fix for carousel wrapper */
  .carouselWrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    overflow-x: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Ensure main containers don't have margins */
  .mainContent,
  .chatContainer,
  .messagesContainer {
    width: 100vw !important;
    max-width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Ensure no sidebars affect the layout at mobile size */
  .withSidebar .inputForm,
  .withProfileSidebar .inputForm,
  .withProfileCard .inputForm,
  .withSidebar.withProfileSidebar .inputForm {
    width: 100vw !important;
    max-width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    border: none !important;
  }

  /* Mobile container override */
  .container,
  .container.withSidebar,
  .container.withProfileSidebar,
  .container.withSidebar.withProfileSidebar {
    margin-top: 0.5rem !important;
    margin-left: 0.5rem !important;
    margin-right: 0.5rem !important;
    width: calc(100% - 1rem) !important;
  }
}

@media (max-width: 768px) {
  .topBlock {
    display: flex;
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    height: 4rem;
    border-radius: 1rem;
    background-color: white;
    width: calc(
      100% - 1rem
    ); /* This is the key fix - subtract the combined left and right margins */
    max-width: calc(
      100% - 1rem
    ); /* Also add max-width constraint for consistency */
    margin: 0; /* Remove any implicit margins */
    box-sizing: border-box; /* Ensure padding doesn't add to the width */
  }
  .centeredInputForm {
    width: calc(100% - 2rem);
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .welcomeTitle,
  .directChatTitle {
    font-size: 1.75rem !important;
  }
  .container,
  .container.withSidebar,
  .container.withProfileSidebar,
  .container.withSidebar.withProfileSidebar {
    width: 100% !important;
    min-height: 100vh !important;
    margin: 0 !important;
    padding: 0 !important;
    border-radius: 0 !important;
    box-shadow: none !important;
    overflow-x: hidden !important;
  }

  /* Fix for the top block to be full width as well */
  .topBlock {
    width: 100% !important;
    max-width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
    z-index: 1;
  }

  .image {
    z-index: 2;
  }

  .sidebarOpenButton {
    top: 0.5rem;
    left: 0.5rem;
  }

  .assistantMessage,
  .userMessage,
  .loadingMessage {
    margin-left: 2rem;
    margin-right: 2rem;
  }
}
