.container {
  min-height: 100vh;
  background-color: white;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.topBlock {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 4rem;
  background-color: white;
  width: 100%;
}

.profileButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 1rem;
  right: 1rem;
  margin: 0;
  padding: 0;
  border: none;
  background: none;
}

.image {
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
  object-fit: cover;
  cursor: pointer;
}

.mainContent {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* padding-bottom: 6.95rem; */
  transition: 0.2s;
}

/* Make withProfileCard behave exactly like withProfileSidebar */
.withProfileCard {
  margin-right: 16rem;
}

/* Update input form positioning */
.withProfileCard .inputForm {
  left: calc(50% - 8rem);
}

.withSidebar .inputForm {
  left: calc(50% + 8rem); /* Changed from 8rem to 12rem (half of 24rem) */
}

.withProfileSidebar .inputForm {
  left: calc(50% - 8rem); /* Changed from 8rem to 12rem (half of 24rem) */
}

.chatContainer {
  width: 40rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}

.withSidebar {
  margin-left: 16rem;
}

.withProfileSidebar {
  margin-right: 16rem;
}

/* When both sidebars are open */
.withSidebar.withProfileSidebar .chatContainer {
  width: calc(100% - 32rem); /* Adjust for both sidebars */
}

.withSidebar .chatContainer {
  margin: 0 auto;
}

.messagesContainer {
  width: 40rem;
  max-width: calc(100% - 2rem);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  padding-top: 4rem;
  padding-bottom: 1.5rem;
}

.userMessage .avatarImage {
  cursor: pointer;
  border-radius: 50%; /* Apply 50% border radius only to user images */
}

/* Updated message styles for avatar display */
.message {
  display: flex;
  /* padding: 1rem; */
  color: black;
  word-wrap: break-word;
  align-items: flex-start;
}

.singleLineMessage {
  align-items: center !important; /* Override the default alignment */
}

/* Avatar styling */
.messageAvatar {
  flex-shrink: 0;
  width: 2rem;
  height: 2rem;
}

.avatarImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  /* Apply border-radius by default */
  border-radius: 50%;
}

/* Override for AI avatars specifically */
.assistantMessage .avatarImage {
  border-radius: 0.25rem; /* Remove border-radius for AI */
}

/* Exception for direct messages - they should have rounded avatars */
.directChatUserAvatar .avatarImage {
  cursor: pointer;
  border-radius: 50%; /* Keep rounded for direct chat */
}

/* For direct messages between users, ensure rounded avatar images */
.directChatUserAvatar {
  border-radius: 50%;
}

/* In direct messages, ensure both sides have rounded avatars */
.directMessage .avatarImage {
  border-radius: 50%;
}

.directMessage {
  white-space: pre-wrap; /* Add this to direct messages specifically */
  word-wrap: break-word; /* Add this to ensure long words break */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  width: 100%; /* Ensure full width */
}

/* Message content container */
.messageContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-left: 0.5rem;
  white-space: pre-wrap; /* Ensure this is present */
  word-wrap: break-word; /* Add this to ensure long words break */
  overflow-wrap: break-word; /* Modern alternative to word-wrap */
  max-width: calc(
    100% - 2.5rem
  ); /* Ensure content doesn't push beyond container width */
}
.userMessage {
  border-radius: 0.5rem;

  background-color: #f8f8f8;
  padding: 1rem;
  color: black;
  margin: 0;
  align-self: flex-end;
}

.welcomeTitle {
  font-size: 2rem;
  font-weight: 900;
  color: black;
  margin: 0;
  padding: 0;
  text-align: center;
  margin-bottom: 2rem;
}
/* In Chat.module.css */
.welcomeMessage {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
  text-align: center;
}

.centeredInputForm {
  position: fixed !important; /* Keep it fixed */
  bottom: 0 !important; /* Keep it at bottom */
  border-radius: 1rem 1rem 0 0 !important;
  /* We can still customize the appearance without changing positioning */
  background-color: white;
  border: 0.05rem solid lightgray;
}
.assistantMessage {
  /* background-color: whitesmoke; */
  /* padding-left: 1rem; */
  /* padding-right: 1rem; */

  border-radius: 0.5rem;

  color: black;
  padding: 0;
  margin: 0;

  display: flex;
  flex-wrap: nowrap;
  /* gap: 1rem; */
}

a {
  word-break: break-all; /* This allows URLs to break at any character */
}

.messageText {
  width: 100%;
  /* padding: 1rem; */
  padding-top: 0;
  padding-bottom: 0;
  white-space: pre-wrap; /* Explicitly ensure wrapping */
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.profileCardsContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 1rem;
  width: 100%;
  margin-top: 1rem;
}

.profileCard {
  background-color: #f8f8f8;
  border-radius: 0.5rem;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 12rem;
  height: 16rem;
  cursor: pointer;
}
.profileCard:hover {
  background-color: #f0f0f0;
}

.profileImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
}

.profileName {
  color: black;
  font-weight: 900;
  font-size: 1.5rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.profileBio {
  display: -webkit-box;
  -webkit-line-clamp: 6;
  line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  color: gray;
}

.welcomeMessage ul {
  list-style: none;
  padding: 0;
  /* margin: 1rem 0; */
}

.welcomeMessage li {
  /* margin: 0.5rem 0; */
  color: orangered;
}

.sidebarOpenButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 30;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s;
}

.sidebarOpenButton:hover {
  background-color: #f0f0f0;
  border-radius: 50%;
}

.sidebarOpenButton2 {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: fixed;
  top: 0.5rem;
  left: 0.5rem;
  z-index: 30;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.disclaimer {
  position: fixed;
  bottom: 0.5rem !important;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: 0.2s;
  font-size: 0.875rem;
  margin: 0;
  padding: 0;
  color: gray;
}

.inputForm {
  border-radius: 1rem 1rem 0 1rem !important;
  position: fixed;
  bottom: 1rem !important;
  left: 50%;
  transform: translateX(-50%);
  width: 40rem;
  max-width: calc(100% - 2rem);
  background-color: #f8f8f8;
  display: flex;
  flex-direction: column;
  z-index: 10;
  transition: 0.2s;
  border: none;
}

.withSidebar.withProfileSidebar .inputForm {
  left: 50%;
}

.chat {
  width: 100%;
  white-space: pre-wrap;
  word-wrap: break-word;
  background: none;
  border: none;
  outline: none;
  color: black;
  font-size: 1rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  resize: none;
  max-height: 25vh;
  padding: 1rem 1rem 0 1rem;
  text-transform: lowercase;
}

.chat::placeholder {
  color: gray;
}

.controlBar {
  padding: 1rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.tag {
  background-color: #f0f0f0;
  /* border: 0.05rem solid lightgray; */
  padding: 0.625rem;
  color: gray;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  font-size: 0.875rem;
}
.tag span {
  margin-right: 0.25rem;
}

.sendButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: orangered;
  border: none;
  color: white;
  font-weight: 900;
  padding: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50% !important;
  transition: 0.2s;
}
.sendButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}
.sendButton:hover {
  filter: brightness(90%);
}

.loadingMessage {
  align-self: flex-start;
  /* padding: 1rem; */
}

.typingIndicator {
  display: flex;
  color: orangered;
  font-weight: 900;
  gap: 0.25rem;
}

@keyframes bounce {
  0%,
  80%,
  100% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

/* Sidebar Styles */
.sidebar {
  width: 16rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: white;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
  z-index: 20;
  transform: translateX(-100%);
}

.sidebar.open {
  transform: translateX(0);
}

.topBar {
  position: fixed;
  top: 1rem;
  left: 1rem;
  z-index: 30;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.toggleButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.toggleButton:hover {
  background-color: #202020;
}

.logo {
  cursor: pointer;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2.25rem;
  height: 2.25rem;
}

.logo:hover {
  background-color: #202020;
}

.title {
  color: #000;
  font-size: 2rem;
  font-weight: 900;
  margin-bottom: 1rem;
}

.subtitle {
  color: gray;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.footer {
  border-top: 0.05rem solid gray;
  margin-top: 1rem;
  padding-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.copyright {
  font-size: 0.875rem;
  color: gray;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.link {
  font-size: 0.875rem;
  color: gray;
  text-decoration: none;
}

.link:hover {
  color: white;
  text-decoration: underline;
}

.buttons {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.tryButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: orangered;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.tryButton:hover {
  filter: brightness(90%);
}

.loginButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: #f8f8f8;
  color: gray;
  padding: 0.5rem 1rem;
  border: none;
  font-weight: 900;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
}

.loginButton:hover {
  filter: brightness(90%);
}

.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  background-color: #202020;
  color: white;
  padding: 0.5rem 1rem;
  border: none;
  font-size: 1rem;
}

.input::placeholder {
  color: gray;
}

.input:focus {
  outline: none;
}

/* Direct chat header styling */
.directChatHeader {
  padding: 1rem;
  border-bottom: 1px solid #f8f8f8;
  margin-bottom: 1rem;
}

.directChatUser {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.directChatUserImage {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 50%;
}

.directChatUserInfo {
  display: flex;
  flex-direction: column;
}

.directChatUserName {
  font-weight: bold;
}

@media (max-width: 768px) {
  .messagesContainer {
    width: 100%;
    max-width: 100%;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .message {
    max-width: 100%;
    box-sizing: border-box;
  }

  .messageContent {
    max-width: calc(100% - 2.5rem);
    overflow-wrap: anywhere; /* Stronger than break-word, will break at any point if needed */
    hyphens: auto; /* Add hyphens for better word breaks */
  }

  /* Target long links specifically */
  .messageContent a,
  .messageText a {
    word-break: break-all;
    display: inline-block;
    max-width: 100%;
  }

  /* Force text to wrap aggressively in user messages (where links often appear) */
  .userMessage {
    overflow-wrap: anywhere;
    word-break: break-word;
  }

  /* Force URLs and long strings to wrap */
  .messageText {
    overflow-wrap: anywhere; /* Most aggressive wrapping */
    word-break: break-word;
    max-width: 100%;
  }
}

/* Add a general rule for non-breaking content like URLs */
.messageContent,
.messageText,
.directMessage {
  overflow-wrap: break-word;
  word-break: break-word; /* Apply to all breakpoints */
}

/* Fix for long URLs with no spaces */
a,
[href],
.messageContent:has(a) {
  word-break: break-all;
  max-width: 100%;
}

/* Direct fixes for the mobile input form */

/* Target the input form specifically at mobile widths */
@media (max-width: 42rem) {
  .topBlock {
    display: flex;
  }
  /* Force full width with no margins or gaps */
  .inputForm {
    left: 1rem !important;
    right: 1rem !important;
    box-sizing: border-box !important;
    transform: none !important;
    border: none !important;

    position: fixed !important;
    bottom: 1rem !important;
  }

  /* Ensure text input inside form is also full width */
  .chat {
    width: 100% !important;
    max-width: 100% !important;
    box-sizing: border-box !important;
  }

  /* Fix for welcome message with carousel */
  .welcomeMessage {
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Fix for the UserCarousel component at mobile sizes */
  .userCarousel {
    width: 100vw !important;
    max-width: 100vw !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Fix for carousel wrapper */
  .carouselWrapper {
    width: 100vw !important;
    max-width: 100vw !important;
    overflow-x: hidden !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  /* Ensure main containers don't have margins */
  .mainContent,
  .chatContainer,
  .messagesContainer {
    /* width: 100vw !important; */
    /* max-width: 100vw !important; */
    margin-left: 0 !important;
    margin-right: 0 !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    box-sizing: border-box !important;
  }

  .messagesContainer {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }

  /* Ensure no sidebars affect the layout at mobile size */
  .withSidebar .inputForm,
  .withProfileSidebar .inputForm,
  .withProfileCard .inputForm,
  .withSidebar.withProfileSidebar .inputForm {
    width: 100vw !important;
    max-width: 100vw !important;
    left: 0 !important;
    right: 0 !important;
    transform: none !important;
    border: none !important;
  }
}

@media (max-width: 768px) {
  .welcomeTitle {
    font-size: 2rem;
  }

  .centeredInputForm {
    width: calc(100% - 2rem);
    max-width: 100%;
  }
}

@media (max-width: 480px) {
  .welcomeTitle {
    font-size: 1.75rem;
  }
}
