/* Global styles */
*,
*::before,
*::after {
  box-sizing: border-box;
}

input,
input[type="text"],
input[type="email"],
input[type="password"],
input[type="number"],
input[type="search"],
input[type="tel"],
input[type="url"],
textarea,
select,
button {
  border-radius: 0.5rem !important;
  -webkit-border-radius: 0.5rem !important;
  -moz-border-radius: 0.5rem !important;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

body {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  overflow-y: scroll; /* Keep scroll functionality */
}

/* Hide WebKit (Chrome, Safari, newer Edge) scrollbar */
body::-webkit-scrollbar {
  display: none;
}

body {
  margin: 0;
  /* text-transform: lowercase; */
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f8f8f8;
  /* text-transform: lowercase; */
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

html {
  background-color: #f8f8f8;

  overflow-x: hidden; /* Prevent horizontal scrolling */
}

#root {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: #f8f8f8;
  overflow-x: hidden; /* Prevent horizontal scrolling */
}

/* Font styles */
p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  font-size: 1.4063rem;
}

h1 {
  font-size: 3.375rem;
}

h2 {
  font-size: 2.8125rem;
}

label {
  color: gray;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

* {
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

p,
h1,
h2,
h3,
h4,
h5,
h6,
span,
div {
  -webkit-user-select: text;
  user-select: text;
}

button {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
