.sidebar {
  width: 15.5rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f8f8f8;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 30;
  transform: translateX(-100%);
  transition: 0.2s;
}

.sidebar.darkMode {
  background-color: #121212;
}

.sidebar.open {
  transform: translateX(0);
}

.topBar {
  position: fixed;
  top: 0;
  left: 0;
  width: 15.5rem; /* Match sidebar width */
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 0.5rem;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background-color: #f8f8f8;
}

.topBar.darkMode {
  background-color: #121212;
}

.tag {
  color: gray;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-left: auto;
}

/* Logo button */
.logoButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  font-weight: 900;
  font-size: 1rem;
}

.darkMode .logoButton:hover {
  background-color: #2a2a2a;
}

.topBarRightButtons {
  display: flex;
  align-items: center;
}

.iconButton {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  position: relative;
}

.iconButton:hover {
  background-color: #f0f0f0;
}

.darkMode .iconButton:hover {
  background-color: #2a2a2a;
}

.logo {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.125rem;
}

/* Add spacer to push content to bottom */
.spacer {
  flex-grow: 1;
}

/* Content wrapper for bottom content */
.contentWrapper {
  padding: 0;
  padding-left: 0.5rem;
  margin-bottom: -1rem;
}

.title {
  color: #000;
  font-size: 1.5rem;
  font-weight: 900;
  margin-bottom: 0.5rem;
  padding: 0 0.5rem;
}

.darkMode .title {
  color: white;
}

.subtitle {
  color: black;
  font-size: 1rem;
  padding: 0 0.5rem;
  margin-bottom: 1rem;
}

.darkMode .subtitle {
  color: #b0b0b0;
}

.footer {
  border-top: 0.05rem solid lightgray;
  padding-top: 1rem;
  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-bottom: 1rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.darkMode .footer {
  border-top: 0.05rem solid #333;
}

.copyright {
  font-size: 0.875rem;
  margin: 0;
  color: gray;
}

.darkMode .copyright {
  color: #b0b0b0;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.smallPrint {
  color: gray;
  font-size: 0.75rem;
  text-decoration: none;
  transition: 0.2s;
}

.smallPrint:hover {
  color: black;
  text-decoration: underline;
}

.darkMode .smallPrint {
  color: #b0b0b0;
}

.darkMode .smallPrint:hover {
  color: white;
}

.link {
  cursor: pointer;
  display: flex;
  border: none;
  align-items: center;
  background-color: orangered;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  color: white;
  transition: 0.2s;

  font-weight: 900;
}

.link:hover {
  filter: brightness(95%);
}

.link.disabled {
  opacity: 0.5 !important;
  cursor: not-allowed;
}

.linkIcon {
  flex-shrink: 0;
  background-color: orangered;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: white;
}

.linkText {
  font-size: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  flex: 1;
  text-align: left;
  justify-content: flex-start;
}

.buttons {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}

.tryButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background-color: orangered;
  color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 900;
  cursor: pointer;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tryButton:hover {
  filter: brightness(90%);
}

.tryButton.disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

.loginButton {
  margin-top: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  border: none;
  color: gray;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  text-decoration: none;
  background: none;
  display: flex;
  align-items: center;
  transition: 0.2s;
}

.loginButton span {
  margin-right: 0.5rem;
}

.loginButton:hover {
  color: black;
  background-color: #f0f0f0;
}

.darkMode .loginButton:hover {
  color: white;
  background-color: #2a2a2a;
}

/* Form-specific styles from LogInSidebar.css */
.form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.input {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f0f0f0;
  color: black;
  padding: 0.5rem;
  border: none;
  font-size: 1rem;
  border-radius: 0.25rem;
}

.darkMode .input {
  background-color: #333;
  color: white;
}

.input::placeholder {
  color: gray;
}

.darkMode .input::placeholder {
  color: #b0b0b0;
}

.input:focus {
  outline: none;
}

.googleIcon {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
}

.googleButton {
  display: flex;
  align-items: center;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 1rem;
  background: none;
  border: none;
  color: gray;
  padding: 0.5rem;
  cursor: pointer;
  text-align: center;
  text-decoration: none;
  transition: 0.2s;
  border-radius: 0.25rem;
}

.googleButton:hover {
  color: black;
  background-color: #f0f0f0;
}

.loading {
  position: relative;
  cursor: not-allowed;
}

/* Loading animation */
@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Message popup styles */
.messagePopup {
  position: fixed;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  gap: 12px;
  background: white;
  padding: 16px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
}

.darkMode .messagePopup {
  background: #333;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

.messagePopupContent {
  display: flex;
  align-items: center;
  gap: 8px;
}

/* Responsive adjustments */
@media (max-width: 60rem) {
  .sidebar.open {
    width: 100%;
  }

  .topBar {
    width: 100%;
    padding-right: 0.5rem;
  }

  .footer {
    margin-right: 1rem;
  }

  .contentWrapper {
    padding-right: 0.5rem;
  }
}
