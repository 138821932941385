.bodyNoScroll {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  overflow: hidden;
}

.modalBackdrop {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  align-items: flex-start; /* Align items to the start to keep them at the top */
  justify-content: center;
  padding-top: 2rem; /* Add padding to the top */
  z-index: 1000; /* Ensure it appears above other content */
  overflow-y: hidden;
}

.introTextArea {
  background-color: #f8f8f8;
  color: black;
  border: 0.05rem solid #f8f8f8;
  margin-bottom: 1rem;
  width: 100%;
  min-height: 16.25rem;
  padding: 0.5rem;
  resize: none;
  overflow-y: auto;
  border-radius: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
  outline: none;
}

.space {
  padding: 0.25rem;
}

.introTextArea:focus {
  outline: none;
  border: 0.05rem solid #f8f8f8;
}

.introTextArea::-webkit-scrollbar {
  display: none;
}

.modalContent {
  position: fixed;
  top: 50%;
  /* left: 50%; */
  /* bottom: 25%; */
  transform: translateX(-50%);
  transform: translateY(-50%);

  /* max-height: calc(100vh - 0rem); */
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  flex-direction: column;
  width: 32rem;
  height: 32rem;
  background-color: white;
  border-radius: 1rem;
  z-index: 1000;
}

.interactions::-webkit-scrollbar {
  display: none;
}

.cardHeader {
  background-color: white;
  color: white;
  padding: 1rem;
  position: sticky;
  top: 0;
  font-size: 1rem !important;
  z-index: 1000;
}

.cardFooter {
  background-color: white;
  padding: 1rem;
  position: fixed;
  bottom: 0rem;
  left: 0;
  right: 0;
  width: 100%;
  z-index: 1000;
}

.confirmText {
  font-size: 1rem;
}
.deleteConfirmation {
  padding: 1rem;
  border-radius: 0.5rem;
  border: 0.05rem solid orangered;
  background-color: rgba(255, 69, 0, 0.1);
}

.interactions {
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  padding-right: 1rem;
  overflow-y: auto;
}

.label {
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.city {
  margin-top: 1rem;
  margin-bottom: 0.25rem;
  font-size: 0.875rem;
}

.linkTextLabel {
  margin-bottom: 0.375rem;
  font-size: 0.875rem;
}

.userInput,
.userInput2,
.userInput3,
.button,
.textArea {
  font-size: 1rem;
  text-transform: lowercase;

  border-radius: 0.5rem;
  box-sizing: border-box;
  outline: none;
  font-family: "Roboto", sans-serif;
}

.smallPrint {
  /* text-align: center; */
  color: gray;
  /* margin-top: 0.5rem; */
  /* margin-left: 1rem;
  margin-right: 1rem; */
  margin-top: 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.smallPrint .charCount {
  margin-top: 0;
}

.smallPrint p {
  font-size: 0.875rem;
  margin-bottom: 0;
  margin-top: -1.25rem;
}

.imageContainer {
  display: flex;
  align-items: center;
  gap: 1rem;
  margin-bottom: 1rem;
}

.uploadButton {
  background-color: white;
  border: none;
  font-size: 1rem;
  padding: 0;
  margin: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  height: fit-content;
}
.uploadButton:hover {
  color: black;
}

.border {
  margin-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.border2 {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border-bottom: 0.05rem solid lightgray;
}

.uploadButton svg {
  width: 1.25rem;
  height: 1.25rem;
}

.profileButton {
  display: none;
}

.profileImage:hover .uploadIcon {
  opacity: 1;
}

.cropContainer {
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
}

.zoomControl {
  width: 100%;
  max-width: 6rem;
  margin: 1rem auto;
}

.zoomSlider {
  display: none;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  height: 0.25rem;
  background: #ddd;
  outline: none;
  opacity: 0.7;
}

.zoomSlider:hover {
  opacity: 1;
}

.zoomSlider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 1rem;
  height: 1rem;
  background: orangered;
  cursor: pointer;
  border-radius: 50%;
}

.zoomSlider::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background: orangered;
  cursor: pointer;
  border-radius: 50%;
}

.profileImage {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;

  position: relative;
}

.footerText {
  display: flex;
  align-items: center;
  font-size: 0.875rem;
  color: gray;
  margin-bottom: 0.5rem;
}

.footerText svg {
  margin-right: 0.25rem;
}
.custom {
  padding-top: 0.5rem;
}

.profileImage img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}

.textArea {
  background-color: #f8f8f8;
  color: black;
  border: 0.05rem solid #f8f8f8;
  margin-bottom: 1rem;
  width: 100%;
  min-height: 2.25rem;
  padding: 0.5rem;

  resize: none;
  overflow-y: auto;
  padding: 0.5rem;
  font-size: 1rem;

  box-sizing: border-box;
  font-family: "Roboto", sans-serif;
}

.textArea::-webkit-scrollbar {
  display: none;
}

.userInput {
  background-color: #f8f8f8;
  color: gray;
  padding: 0.5rem;
  border: 0.05rem solid #f8f8f8;
  margin-bottom: 1rem;
  border-radius: 0.5rem;
  font-weight: 900;
  width: 100%;
  cursor: pointer;
}
.userInput:hover {
  background-color: #f0f0f0;
}

.userInput2 {
  border-radius: 0.5rem;
  background-color: white;
  color: gray;
  font-weight: 900;
  padding: 0.5rem;
  cursor: pointer;
  font-size: 1rem;
  border: none;
  width: 100%;
  margin-top: 0.5rem;
}

.userInput3 {
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  color: black;
  padding: 0.5rem;
  font-size: 1rem;
  border: 0.05rem solid #f8f8f8;
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.userInput::placeholder {
  color: grey;
}

.button {
  background-color: orangered;
  border: 0.05rem solid orangered;
  color: white;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 900;
  border-radius: 0.5rem;
}
.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(90%);
}

.cardTitle {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: black;
  font-size: 1rem !important;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.charCount {
  font-size: 0.875rem;
  color: gray;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 1rem;
}

.disabledInput {
  opacity: 0.5;
  cursor: not-allowed;
}

.closeIcon {
  background-color: white;
  padding: 0.5rem;
  border-radius: 0.5rem;
  cursor: pointer;
  width: fit-content;
  font-size: 1rem;
  font-weight: 400;
  display: flex;
  align-items: center;
  align-self: flex-end;
  color: gray;

  border: none;
}

.closeIcon:hover {
  background-color: #f0f0f0;
}

.loadingIndicator {
  display: flex;
  justify-content: center;
  padding: 0;
}

.loadingIcon {
  animation: spin 1s linear infinite;
  font-size: 1rem; /* Adjust size as needed */
  color: white; /* Adjust color as needed */
}
@media (max-width: 991px) {
  .modalContent {
    position: absolute;
    top: 0rem;
    bottom: 0rem;
    height: 100%;
    left: 0;
    right: 0;
    transform: translateX(0%);
    max-height: calc(100vh - 0rem);
    width: 100%;
    border-radius: 0;
    z-index: 1000;
  }

  .imageContainer:hover img {
    opacity: 1;
  }

  .cardFooter {
    padding-bottom: 2rem;
  }

  .interactions {
    padding-bottom: 6.25rem;
  }
}
