.sidebar {
  width: 15.5rem;
  position: fixed;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #f8f8f8;
  color: black;
  display: flex;
  flex-direction: column;
  z-index: 40;
  transform: translateX(100%);
  /* text-transform: lowercase; */
  padding: 0;
  /* border-radius: 1rem 0 0 0; */
  transition: 0.2s;
}

.sidebar * {
  word-wrap: break-word;
  overflow-wrap: break-word;
  word-break: break-word;
  hyphens: none; /* Change from 'auto' to 'none' to prevent hyphenation */
  max-width: 100%;
}

.sidebar a,
.sidebar .link {
  hyphens: auto;
}

.footer {
  margin: 0;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-right: 0.5rem;
  margin-left: 0rem;
}

.footerButton {
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: white;
  background-color: orangered;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  text-decoration: none;
  font-weight: 900;
  transition: 0.2s;
}

.footerButton:hover {
  filter: brightness(90%);
}

.closeButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute; /* Position it within the sidebar */
  top: 0.5rem;
  left: 0.5rem; /* Position at the left side of the sidebar */
  z-index: 41;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  width: fit-content;
  transition: 0.2s;
}

/* Update the sidebarHeader to properly account for the close button */
.sidebarHeader {
  display: flex;
  justify-content: flex-end; /* Push action buttons to the right */
  padding-right: 0.5rem;
  padding-top: 0.5rem;
  padding-bottom: 2rem;
  padding-top: 2rem;

  position: sticky;
  top: 0;
  z-index: 41;
  background-color: #f8f8f8;
  border-radius: 1rem 0 0 0;
}

.closeBtn {
  transition: 0.2s;
  position: absolute;
  top: 0.5rem;
  left: 0rem;
  background: none;
  border: none;
  cursor: pointer;
  z-index: 50;
  padding: 0.5rem;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
}

.closeBtn:hover {
  background-color: #f0f0f0;
  border-radius: 0.5rem;
}

.actionButtons {
  display: flex;
}

.iconButton {
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.iconButton:hover {
  background-color: #f0f0f0;
}

.addButton {
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  color: white;
  background-color: orangered;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
}

.addButton:hover {
  filter: brightness(90%);
}

/* Add styles for the settings modal menu items */
.settingsMenuItems {
  display: flex;
  flex-direction: column;
}

.settingsMenuItem {
  font-family: "Roboto", sans-serif;
  color: gray;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.settingsMenuItem:hover {
  background-color: #f0f0f0;
  color: black;
}

.linkIcon svg {
  width: 1.25rem;
  height: 1.25rem;
  color: gray; /* Or whatever color you want */
}

.topHalf {
  display: flex;
  flex-direction: column;
  padding: 0 0rem 1rem 0.5rem; /* SET EQUAL PADDING LEFT AND RIGHT */
  overflow-y: auto;

  flex: 1;
}

.sidebar.open {
  transform: translateX(0);
}

/* Profile section */
.profileSection {
  display: flex;
  flex-direction: column;
}

.nameEditContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  width: 100%;
}

.editIcon {
  width: 1rem;
  height: 1rem;
}

.section {
  display: flex;
  flex-direction: column;
}

.button {
  transition: 0.2s;
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  display: flex;
}

.button:hover,
.editButton:hover {
  color: black;
  background-color: #f0f0f0;
}

.deleteConfirmation {
  display: flex;
  flex-direction: column;
  font-size: 1rem;
}

.confirmText {
  font-size: 0.875rem;
  color: black;
  margin: 0.5rem 0;
  font-weight: 400;
}

.deleteConfirmation .confirmButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  background-color: orangered;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0;
  border: none;
  display: flex;
  border-radius: 0.5rem;
}

.deleteConfirmation .cancelButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray;
  font-weight: 400;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  border-radius: 0.5rem;
}

.deleteConfirmation .cancelButton:hover {
  color: black;
  background-color: #f0f0f0;
}

.deleteButton {
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0;
  border: none;
  background: none;
  display: flex;
  border-radius: 0;
}

.editButton {
  transition: 0.2s;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: gray;
  font-size: 1rem;
  padding: 0.5rem;
  cursor: pointer;
  margin: 0;
  border: none;
  border-radius: 0.5rem;
  background: none;
  display: flex;
  margin-top: 1rem;
}

.profileImage {
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  object-fit: cover;
  margin-bottom: 0.5rem;
}

.startupImage {
  width: 4rem;
  height: 4rem;
  object-fit: cover;
  margin-bottom: 0.5rem;
  border-radius: 0.5rem;
}

.profileName {
  font-size: 1.5rem;
  font-weight: 600;
  color: black;
  margin-bottom: 0.25rem;
  margin-right: 1rem;
}

/* Bio and basic info */
.bio {
  color: black;
  font-size: 1rem;
  margin-bottom: 0;
  margin-right: 1rem;
}

.bold {
  font-weight: 900;
  color: black;
}

.intro {
  margin: 0;
  margin-top: 1rem;
  color: black;
  font-size: 1rem;
  white-space: pre-wrap;
  margin-right: 1rem;
}

.infoRow {
  display: flex;
  flex-direction: column;
  color: gray;
  margin-top: 0.25rem;
  margin-right: 1rem;
}

.sectionTitle {
  /* border-top: 0.05rem solid lightgray; */
  font-size: 1rem;
  color: black;
  font-weight: 900;
  margin-top: 1rem;
  padding-top: 1rem;
  margin-bottom: 1rem;
}

/* Card styles for experience and education */
.card {
  padding-top: 1rem;
  margin-top: 1rem;
  /* border-top: 0.05rem solid lightgray; */
}

.iconContainer {
  display: flex;
  gap: 0.5rem;
}

.editIcon,
.trashIcon,
.confirmIcon,
.cancelIcon {
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  cursor: pointer;
  width: 2.25rem;
  height: 2.25rem;
  border-radius: 0.5rem;
  background-color: #f8f8f8;
  margin-right: 0.5rem;
}

.editIcon {
  margin-right: 0.5rem;
}

.trashIcon {
  margin-left: -1rem;
}

.confirmIcon {
  margin-right: -0.5rem;
}

.editIcon:hover,
.trashIcon:hover,
.cancelIcon:hover {
  background-color: #f0f0f0;
}

.confirmDeleteIcon {
  background-color: orangered;
  color: white;
}

.confirmDeleteIcon:hover {
  background-color: #ff3300; /* Slightly darker orangered */
  color: white;
}

.profileImageContainer {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.section > .card:first-of-type {
  border-top: none !important;
  padding-top: 0;
  margin-top: 0;
}

/* If needed, you can also make it more specific by targeting the exact sections */
.section:has(.sectionTitle:contains("Experience")) > .card:first-of-type,
.section:has(.sectionTitle:contains("Education")) > .card:first-of-type {
  border-top: none !important;
  padding-top: 0;
  margin-top: 0;
}

.cardHeader {
  display: flex;
  margin-bottom: 0.25rem;
}

.cardIcon {
  color: orangered;
}

.cardTitle {
  color: black;
}

.cardRole {
  color: gray;
  margin-bottom: 0.25rem;
}

.cardSubject {
  color: gray;
  margin-bottom: 0.25rem;
}

.cardGrade {
  color: gray;
  margin-bottom: 0.25rem;
}

.cardDate {
  color: gray;
}

.description {
  color: gray;
  font-size: 0.875rem;
  margin: 0;
  white-space: pre-wrap;
}

.moreButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: orangered;
  cursor: pointer;
  font-weight: 900;
  margin-left: 0.25rem;
}

.moreButton:hover {
  text-decoration: underline;
}

/* LINKS CONTAINER - SIMPLIFIED AND FIXED */
.linksContainer {
  margin-top: 1rem;
  margin-left: -0.5rem;
  margin-right: -0.5rem;
  width: calc(100% + 1rem);
  padding: 0;
  box-sizing: border-box;
}

.linkIcon {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  min-width: 1.25rem;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: gray;
}

.link {
  display: flex;
  align-items: center;
  padding: 0.5rem;
  margin: 0;
  width: 100%;
  box-sizing: border-box;
  border-radius: 0.5rem;
  text-decoration: none;
  color: gray;
  transition: 0.2s;
  overflow: hidden;
}

.linkText {
  font-size: 1rem;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-all;
  hyphens: auto;
  flex: 1;
  max-width: 100%;
  white-space: nowrap; /* Prevents text from wrapping */
  overflow: hidden; /* Hides any overflow */
  text-overflow: ellipsis;
}

.link:hover {
  color: black;
  background-color: #f0f0f0;
}

/* Skills and Interests tags */
.tagContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.tag {
  width: fit-content;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: rgba(255, 69, 0, 0.1);
  border: solid 0.05rem transparent;
  color: orangered;
  font-weight: 900;
  padding: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  margin: 0;
}

.tag svg {
  margin-right: 0.25rem;
  display: flex;
}

.tag span {
  word-break: break-word;
  flex: 1;
}

/* Loading state */
.loading {
  display: flex;
  padding: 2rem;
  color: gray;
}

/* Empty state */
.empty {
  color: gray;
  text-align: center;
  padding: 1rem;
  font-size: 0.875rem;
}

/* Responsive styles */
@media (max-width: 76rem) {
  body.withSidebar.withProfileSidebar .sidebar.open {
    width: 100%;
  }
}

@media (max-width: 60rem) {
  .sidebarHeader {
    padding-left: 0.5rem;
  }

  .closeBtn {
    left: 0.5rem;
  }

  .topHalf {
    padding: 0 0rem 1rem 1rem; /* EQUAL PADDING ON BOTH SIDES */
  }

  .sidebar.open {
    width: 100%;
  }

  .footer {
    margin-left: 0.5rem;
  }
}

.confirmationModal {
  position: relative;
  z-index: 1001;
  width: 16rem;
  max-height: 16rem;
  background-color: white;
  overflow: hidden;
}

.confirmationContent {
  padding: 1rem;
}

.confirmationContent h3 {
  margin-top: 0;
  font-size: 1rem;
  margin-bottom: 0.75rem;
  font-weight: 900;
}

.confirmationContent p {
  margin: 0 0 1.5rem;
  font-size: 1rem;
  color: gray;
}

.settingsContainer {
  display: flex;
  flex-direction: column;
}

.caretUp {
  display: inline-block;
}

.caretDown {
  display: inline-block;
  transform: rotate(180deg);
}

.activeButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: black;
  background-color: #f0f0f0;
}

.passwordInputs {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-bottom: 1rem;
}

.userInput {
  padding: 0.5rem;
  background-color: #f0f0f0;
  border: none;
  font-size: 1rem;
  width: 100%;
}

.confirmationButtons {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.confirmButton {
  border-radius: 0.5rem;
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  padding: 0.5rem;
  background-color: orangered;
  color: white;
  border: none;
  font-weight: 900;
  cursor: pointer;
  font-size: 1rem;
}

.disabledButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  opacity: 0.5;
  cursor: not-allowed;
}

.error {
  color: orangered;
  margin-top: 0.5rem;
  font-size: 0.875rem;
}
