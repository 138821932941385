/* Sidebar.module.css */
.sidebar {
  width: 15.5rem;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  background-color: #f8f8f8;
  color: white;
  display: flex;
  flex-direction: column;
  z-index: 30;
  transform: translateX(-100%);
  transition: 0.2s;
  /* border-radius: 0 1rem 0 0; */
  /* box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.2); */
}

.sidebar.darkMode {
  background-color: #121212;
}

.sidebar.open {
  transform: translateX(0);
}

.topBar {
  border-radius: 0 1rem 0 0;

  position: fixed;
  top: 0;
  left: 0;
  width: 15.5rem; /* Match sidebar width */
  z-index: 40;
  display: flex;
  align-items: center;
  justify-content: space-between; /* This ensures logo on left, buttons on right */
  padding-left: 0.5rem;
  padding-right: 0rem;
  padding-top: 0.5rem;
  padding-bottom: 0;
  background-color: #f8f8f8;
}

.topBar.darkMode {
  background-color: #121212;
}

/* Add spacer to push footer to bottom */
.spacer {
  flex-grow: 1;
}

/* Logo button */
.logoButton {
  display: flex;
  align-items: center;
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
  transition: 0.2s;
}

.darkMode .logoButton:hover {
  background-color: #2a2a2a;
}

.logoButton span {
  margin-left: 0.5rem;
  font-weight: 900;
  font-size: 1rem;
}

/* Simplify the right buttons container */
.topBarRightButtons {
  display: flex;
  align-items: center;
}

.chatButton.active {
  background-color: #f0f0f0;
}

.darkMode .chatButton.active {
  background-color: #2a2a2a;
}

.chatButton.active .chatTitle {
  color: black;
}

.darkMode .chatButton.active .chatTitle {
  color: white;
}

/* Keep material icons gray */
.chatButton .material-symbols-outlined {
  color: gray;
}

.darkMode .chatButton .material-symbols-outlined {
  color: #b0b0b0;
}

/* New topHalf section for the navigation menu */
.topHalf {
  margin-top: 3.75rem;
  padding: 0.5rem;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 1rem;
  display: flex;
  flex-direction: column;
}

/* Added for icon + text alignment in chat buttons */
.chatButtonContent {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.unreadCount {
  margin-left: auto;
  background-color: orangered;
  color: white;
  font-weight: 900;
  border-radius: 50%;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 0.75rem;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* Ensure icon buttons maintain proper positioning */
.iconButton {
  font-family: "Roboto", sans-serif;
  display: flex;
  align-items: center;
  justify-content: center; /* Center icons horizontally */
  background: none;
  border: none;
  color: gray;
  cursor: pointer;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  position: relative; /* Important for badge positioning */
}

.iconButton:hover {
  background-color: #f0f0f0;
}

.darkMode .iconButton:hover {
  background-color: #2a2a2a;
}

.iconButton.active {
  background-color: #f0f0f0;
}

.darkMode .iconButton.active {
  background-color: #2a2a2a;
}

.chatList {
  padding: 0.5rem;
  padding-right: 0;
  overflow-y: auto;
  padding-top: 0;
  margin-bottom: 0;
  padding-bottom: 0;
  color: black;
}

.darkMode .chatList {
  color: white;
}

/* Container for chat button and its dropdown */
.chatItemContainer {
  position: relative;
}

.chatButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  color: gray;
  border: none;
  background: transparent;
  cursor: pointer;
  /* text-transform: lowercase; */
  transition: 0.2s;
}

.searchButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-weight: 400;
  text-align: left;
  display: flex;
  align-items: center;
  color: black;
  border: none;
  background-color: #f0f0f0;
  cursor: pointer;
  /* text-transform: lowercase; */
  transition: 0.2s;
}

.darkMode .chatButton {
  color: #b0b0b0;
}

/* Add this rule to make the chat button appear active when delete confirmation is shown */
.chatItemContainer:has(.iconContainer) .chatButton {
  color: black;
  background-color: #f0f0f0;
}

.darkMode .chatItemContainer:has(.iconContainer) .chatButton {
  color: white;
  background-color: #2a2a2a;
}

/* Alternative if :has() selector isn't supported in all target browsers */
.chatButton.showingDeleteConfirmation {
  color: black;
  background-color: #f0f0f0;
}

.darkMode .chatButton.showingDeleteConfirmation {
  color: white;
  background-color: #2a2a2a;
}

.chatButton:hover {
  color: black;
  background-color: #f0f0f0;
}

.darkMode .chatButton:hover {
  color: white;
  background-color: #2a2a2a;
}

/* Menu trigger button (the ellipsis) */
.menuTrigger {
  visibility: hidden;
  opacity: 0;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  background: none;
  border: none;
  padding: 0.5rem;
  cursor: pointer;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
  background: linear-gradient(rgba(240, 240, 240, 0), #f0f0f0 10%);
  transition: 0.2s;
  z-index: 5;
}

.darkMode .menuTrigger {
  color: #b0b0b0;
  background: linear-gradient(rgba(42, 42, 42, 0), #2a2a2a 10%);
}

.menuTrigger:hover {
  background: linear-gradient(rgba(240, 240, 240, 0), #f0f0f0 10%);
}

.darkMode .menuTrigger:hover {
  background: linear-gradient(rgba(42, 42, 42, 0), #2a2a2a 10%);
}

.menuTrigger .material-symbols-outlined {
  font-size: 1rem;
}

/* Show the ellipsis when hovering over the chat button */
.chatButton:hover .menuTrigger {
  visibility: visible;
  opacity: 1;
}

/* Make the ellipsis always visible when showMenu is set */
.chatButtonWithOpenMenu .menuTrigger {
  visibility: visible;
  opacity: 1;
}

/* Improved gradient background behind the ellipsis */
.chatButton:hover .menuTrigger::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  padding: 0.25rem;
  height: 100%;
  background: linear-gradient(to right, rgba(240, 240, 240, 0), #f0f0f0 70%);
  border-radius: 0.5rem;
  z-index: -1;
}

.darkMode .chatButton:hover .menuTrigger::before {
  background: linear-gradient(to right, rgba(42, 42, 42, 0), #2a2a2a 70%);
}

/* Active chat button special handling */
.chatButton.active:hover .menuTrigger::before {
  background: linear-gradient(to right, rgba(240, 240, 240, 0), #f0f0f0 70%);
}

.darkMode .chatButton.active:hover .menuTrigger::before {
  background: linear-gradient(to right, rgba(42, 42, 42, 0), #2a2a2a 70%);
}

/* Add the unreadChat style */
.chatButton.unreadChat {
  border-left: 0.25rem solid orangered;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
}

.chatTitle {
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
}

.chatDate {
  font-size: 0.75rem;
  color: gray;
}

.darkMode .chatDate {
  color: #b0b0b0;
}

.emptyState {
  margin-top: 1rem;
  color: gray;
  text-align: center;
  font-size: 0.875rem;
}

.darkMode .emptyState {
  color: #b0b0b0;
}

.chatButtonWithOpenMenu {
  background-color: #f0f0f0; /* Same as the active state */
}

.darkMode .chatButtonWithOpenMenu {
  background-color: #2a2a2a;
}

/* Updated styles for the inline delete confirmation */
.iconContainer {
  display: flex;
  gap: 0.25rem;
  position: absolute;
  right: -0.5rem;
  top: 50%;
  transform: translateY(-50%);
  z-index: 5;
  background: linear-gradient(rgba(240, 240, 240, 0), #f0f0f0 10%);
  border-radius: 0.25rem;
  padding: 0 0.25rem;
}

.darkMode .iconContainer {
  background: linear-gradient(rgba(42, 42, 42, 0), #2a2a2a 10%);
}

.confirmDeleteBtn,
.cancelDeleteBtn {
  background: none;
  border: none;
  padding: 0.25rem;
  cursor: pointer;
  color: gray;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0.25rem;
}

.darkMode .confirmDeleteBtn,
.darkMode .cancelDeleteBtn {
  color: #b0b0b0;
}

.confirmDeleteBtn:hover {
  color: orangered;
}

.cancelDeleteBtn:hover {
  background-color: #f0f0f0;
}

.darkMode .cancelDeleteBtn:hover {
  background-color: #2a2a2a;
}

.confirmDeleteBtn .material-symbols-outlined,
.cancelDeleteBtn .material-symbols-outlined {
  font-size: 1rem;
}

/* Add this to improve the background consistency */
.iconContainer::before {
  content: "";
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(240, 240, 240, 0), #f0f0f0 70%);
  border-radius: 0.5rem;
  z-index: -1;
}

.darkMode .iconContainer::before {
  background: linear-gradient(to right, rgba(42, 42, 42, 0), #2a2a2a 70%);
}

/* Footer styles added for consistency with other sidebars */
.footer {
  border-top: 0.05rem solid lightgray;
  padding: 0;
  padding-top: 1rem;
  margin-bottom: 1rem;

  margin-left: 1rem;
  margin-right: 0.5rem;
  margin-top: 1rem;

  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.darkMode .footer {
  border-top: 0.05rem solid #333;
}

.links {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 0.5rem;
}

.smallPrint {
  color: gray;
  font-size: 0.75rem;
  text-decoration: none;
  transition: 0.2s;
}

.smallPrint:hover {
  color: black;
  text-decoration: underline;
}

.darkMode .smallPrint {
  color: #b0b0b0;
}

.darkMode .smallPrint:hover {
  color: white;
}

.tag {
  color: gray;
  border-radius: 10rem;
  display: flex;
  align-items: center;
  font-size: 0.75rem;
  margin-left: auto;
}

.darkMode .tag {
  color: #b0b0b0;
}

@media (max-width: 76rem) {
  body.withSidebar.withProfileSidebar .sidebar.open {
    width: 100%;
  }
}

@media (max-width: 60rem) {
  .footer {
    margin-right: 1rem;
  }

  .topBar {
    padding-right: 0.5rem;
  }

  .topHalf {
    padding-right: 0.5rem;
  }

  .chatList {
    padding-right: 0.5rem;
  }

  .sidebar.open {
    width: 100%;
  }

  .topBar {
    width: 100%;
  }

  .buttons {
    display: flex;
    flex-direction: column;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
}

.logo {
  width: 1.25rem;
  height: 1.25rem;
  border-radius: 0.25rem;
}

/* Analytics styles to match screenshot with icons */
.analyticsContainer {
  height: 100%;
  color: black;
  /* background-color: #f0f0f0; */

  border-radius: 0.5rem;
  padding: 0.5rem;
}

.darkMode .analyticsContainer {
  color: white;
}

.statRow {
  display: flex;
  align-items: flex-start;
}

.statIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
  color: gray;
}

.statContent {
  flex: 1;
}

.statsLabel {
  color: gray;
  font-size: 0.875rem;
  /* text-transform: lowercase; */
  margin-bottom: 0.25rem;
}

.statNumber {
  font-size: 1rem;
  margin-bottom: 1rem;
}

.keywordsText {
  font-size: 1rem;
  /* text-transform: lowercase; */
  color: black;
}

/* Loading indicator for analytics */
.loadingContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: orangered;
  font-weight: 900;
}

.loadingText {
  font-size: 1rem;
}

.buttons {
  display: flex;
  flex-direction: column;
  padding-left: 0.5rem;
  padding-right: -0.5rem;
}

.tryButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  background-color: orangered;
  text-decoration: none;
  color: white;
  text-align: center;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* text-transform: lowercase; */
  transition: 0.2s;
  margin-bottom: 0.5rem;
}
.tryButton:hover {
  filter: brightness(90%);
}

.loginButton {
  font-family: "Roboto", sans-serif;
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  padding: 0.75rem 1rem;
  border-radius: 0.5rem;
  font-weight: 600;
  background-color: #f0f0f0;
  text-decoration: none;
  color: gray;
  text-align: center;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  /* text-transform: lowercase; */
  transition: 0.2s;
}
.loginButton:hover {
  background-color: #e0e0e0;
}

.foundry {
  color: black;
  font-weight: 600;
  margin-left: 0.5rem;
  font-size: 1rem;
}
