/* Fused ProfileModal.module.css with SignUp.module.css styling */

.page {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  z-index: 1000;
  overflow-y: auto;
  padding-top: 2rem;
}

.topBlock {
  display: none;
}

.closeButton {
  position: fixed;
  top: 0.5rem;
  right: 0.5rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border-radius: 0.5rem;
  transition: 0.2s;
  z-index: 1001;
  background-color: white;
}

.closeButton:hover {
  background-color: #f8f8f8;
}

.content {
  width: 100%;
  max-width: 24rem;
  margin-top: 2rem;
  margin-bottom: 2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.formContainer {
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
  max-width: 24rem;
}

.formContent {
  width: 100%;
}

.formAbout {
  text-align: center;
}

.primaryAbout {
  font-weight: 600;
  font-size: 1.5rem;
  margin-bottom: 2rem;
  margin-left: 1rem;
  margin-right: 1rem;
}

/* Profile Image styles */
.imageContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1rem;
  margin-bottom: 2rem;
}

.profileImage {
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
  display: flex;
  font-weight: 900;
  font-size: 5rem;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0.1rem solid #e0e0e0;
}

.profileImage img {
  width: 6rem;
  height: 6rem;
  object-fit: cover;
}

.profileButton {
  display: none;
}

.uploadButton {
  background-color: white;
  border: 0.1rem solid #e0e0e0;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin: 0 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  height: fit-content;
  transition: 0.2s;
  width: fit-content;
}
.uploadButton:hover,
.linkButton:hover {
  background-color: #f8f8f8;
}

.linkButton {
  background-color: white;
  border: 0.1rem solid #e0e0e0;
  font-size: 1rem;
  padding: 0.75rem 1rem;
  margin: 0 !important;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  color: gray;
  height: fit-content;
  transition: 0.2s;
  width: fit-content;
  margin-bottom: 1rem !important;
}

/* Cropper styles */
.cropContainer {
  position: relative;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  overflow: hidden;
}

/* Form interaction styles */
.interactions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.remove {
  font-size: 0.875rem;
  margin-top: 0.5rem;
  color: orangered;
  font-weight: 600;
}
.remove:hover {
  text-decoration: underline;
}

.userInput,
.userInput2,
.userInput3,
.textArea,
.linkInput,
.introTextArea {
  background-color: #f8f8f8;
  color: black;
  border: 0.1rem solid #e0e0e0;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  box-sizing: border-box;
  outline: none;
  font-family: inherit;
  border-radius: 0.5rem;
  width: 100%;
}

.userInput {
  margin-bottom: 1rem;
}

.userInput3 {
  margin-top: -0.5rem;
  margin-bottom: 1rem;
}

.textArea {
  resize: none;
  min-height: 2.25rem;
  margin-bottom: 1rem;
  overflow-y: auto;
}

.textArea::-webkit-scrollbar,
.introTextArea::-webkit-scrollbar {
  display: none;
}

/* For intro textarea */
.introTextArea {
  width: 100%;
  min-height: 16.25rem;
  resize: none;
  margin-bottom: 1rem;
  overflow-y: auto;
}

.space {
  padding: 0.25rem;
}

/* Button styles */
.button {
  border-radius: 0.5rem;
  background-color: orangered;
  border: none;
  color: white;
  font-weight: 600;
  cursor: pointer;
  padding: 0.75rem 1rem;
  font-size: 1rem;
  width: 100%;
  margin-top: 1rem;
  transition: 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;
}

.button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.button:hover:enabled {
  filter: brightness(90%);
}

/* Links section */
.linksSection {
  margin-bottom: 0.5rem;
  width: 100%;
}

.linksSectionHeader {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-bottom: 0.5rem;
}

.addLinkButton {
  background: none;
  border: none;
  color: #666;
  cursor: pointer;
  font-size: 0.875rem;
  padding: 0;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.addLinkButton:hover {
  color: #000;
}

.linkContainer {
  margin-bottom: 0.5rem;
  position: relative;
}

.linkInputs {
  display: flex;
  gap: 0.5rem;
  width: 100%;
}

.inputError {
  border-color: orangered;
}

.errorText {
  color: orangered;
  font-size: 0.75rem;
  margin-top: 0.25rem;
}

.removeButton {
  background: none;
  border: none;
  color: #ff4600;
  font-size: 0.75rem;
  cursor: pointer;
  padding: 0;
  white-space: nowrap;
}

.removeButton:hover {
  text-decoration: underline;
}

/* URL input container */
.urlInputContainer {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  justify-content: space-between;
}

.urlInputContainer .linkInput {
  flex: 1;
}

/* Loading indicator */
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loadingIcon {
  padding: 0.1rem;
  color: white;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: spin 1s linear infinite;
}

.loading {
  position: relative;
}

/* Link styles */
.link {
  color: orangered;
  text-decoration: none;
  cursor: pointer;
  font-weight: 600;
}

.link:hover {
  text-decoration: underline;
}

/* Error popup */
.repostPopup {
  position: fixed;
  bottom: 1rem;
  left: 1rem;
  background-color: white;
  padding: 1rem;
  border-radius: 0.5rem;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  z-index: 1600;
  width: 20rem;
}

.repostPopup p {
  font-size: 0.875rem;
  margin-bottom: 0.125rem;
  margin-right: 0.25rem;
}

.repostPopupContent {
  display: flex;
  align-items: center;
}

.repostCloseButton {
  position: absolute;
  top: 0.5rem;
  right: 0.5rem;
  width: 2rem;
  height: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  transition: 0.2s;
}

.tickIcon {
  margin-right: 0.5rem;
}

.closeIcon {
  transition: 0.2s;
  border-radius: 50%;
  padding: 0.25rem;
  cursor: pointer;
  z-index: 10;
}

.closeIcon:hover {
  background-color: #f8f8f8;
}

/* Form labels */
.label {
  display: block;
  font-size: 0.875rem;
  color: gray;
  margin-bottom: 0.5rem;
}

/* Form groups */
.formGroup {
  margin-bottom: 1rem;
  width: 100%;
}

/* Media queries */
@media (max-width: 768px) {
  .topBlock {
    display: flex;
    position: fixed;
    top: 0.5rem;
    left: 0.5rem;
    right: 0.5rem;
    height: 3.25rem;
    border-radius: 1rem;
    background-color: white;
    width: calc(100% - 1rem);
    max-width: calc(100% - 1rem);
    margin: 0;
    box-sizing: border-box;
    z-index: 1000;
  }

  .content {
    padding-top: 1.375rem !important;
  }

  .closeButton {
    z-index: 1002;
  }

  .urlInputContainer {
    flex-direction: column;
    align-items: flex-start;
  }

  .removeButton {
    align-self: flex-end;
  }

  .linkInputs {
    flex-direction: column;
    gap: 0.5rem;
  }
}

@media (max-width: 480px) {
  .page {
    padding: 0;
  }

  .topBlock {
    width: 100% !important;
    max-width: 100% !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
    border-radius: 0 !important;
    margin: 0 !important;
  }

  .content {
    padding: 4rem 1rem 1rem;
  }
}
